import React, { useEffect, useState } from "react";
import { Button, Dropdown, Icon, Menu, notification, Switch } from "antd";
import QRCode from "qrcode.react";
import html2canvas from "html2canvas";
import { saveAs } from "file-saver";
import PDFDocument from "@react-pdf/pdfkit";
import SVGtoPDF from "svg-to-pdfkit";
import BlobStream from "blob-stream";
import StickerModal from "../StickerModal";
import { getCompanyCurrentUser } from "../../services/auth";
import {
  MELI_MOOCA,
  MOBILIZE,
  NEO,
  NEOCHARGE,
  SHELL,
  BYD,
  BYD_TEST,
  BRS,
  VOLTZ,
  ION,
} from "../../config";
import "./styles.css";
import { useFreire } from "../../utils/freireContext";
import * as texts from "./locales";
import {
  QRCODE_MOBILIZE,
  QRCODE_NEO,
  QRCODE_SHELL,
  QRCODE_TUPI,
  QRCODE_BYD,
  QRCODE_BRS,
  QRCODE_VOLTZ,
  QRCODE_ION,
} from "./configs";

const ButtonGroup = Button.Group;

function QrCode({ station = {}, size = 424 }) {
  const [hasLogo, setHasLogo] = useState(true);
  const [companyID, setCompanyID] = useState("");
  const [parentCompanyID, setParentCompanyID] = useState("");
  const [visibleSticker, setVisibleSticker] = useState(false);
  const [sizeSticker, setSizeSticker] = useState("16x6");
  const [qrcodeSticker, setQrcodeSticker] = useState("");
  const { freire } = useFreire();

  async function getCompanyID() {
    const result = await getCompanyCurrentUser();
    if (result) {
      setCompanyID(result.id);
      setParentCompanyID(result.parentCompanyID);
    }
  }

  useEffect(() => {
    getCompanyID();
  }, []);

  const downloadPNG = () => {
    var container = document.querySelector(".qrcode_capture");
    var svg = container.getElementsByTagName("svg")[0];
    var image = container.getElementsByTagName("image")[0];
    svg.removeAttribute("xmlns");
    image.removeAttribute("xmlns:xlink");

    html2canvas(container).then((canvas) => {
      const pngUrl = canvas
        .toDataURL("image/png")
        .replace("image/png", "image/octet-stream");
      let downloadLink = document.createElement("a");
      downloadLink.href = pngUrl;
      downloadLink.download = `${station.name} (${station.stationID}) - QrCode.png`;
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
    });
  };

  const downloadSVG = () => {
    var container = document.querySelector(".qrcode_capture");
    var svg = container.getElementsByTagName("svg")[0];
    // svg.style.padding = 12;

    var image = container.getElementsByTagName("image")[0];
    svg.setAttribute("xmlns", "http://www.w3.org/2000/svg");
    image.setAttribute("xmlns:xlink", "http://www.w3.org/1999/xlink");

    var file = new File(
      [container.innerHTML],
      `${station.name} (${station.stationID}) - QrCode.svg`,
      {
        type: "image/svg+xml",
      },
    );

    // svg.style.padding = 0;
    saveAs(file);
  };

  const downloadPDF = () => {
    try {
      const sticker = document.getElementById("sticker");
      const stickerSvg = sticker
        .getSVGDocument()
        .getElementsByTagName("svg")[0];

      const viewBox = stickerSvg.getAttribute("viewBox").split(" ");
      const withBorder = stickerSvg.getAttribute("type") === "border";
      const width = Number(viewBox[2]);
      const height = Number(viewBox[3]);

      const doc = new PDFDocument({
        size: [width + 40, height + 40],
      });

      const space = withBorder ? 20 : 0;
      SVGtoPDF(doc, stickerSvg.outerHTML, space, space, {
        assumePt: true,
      });

      const stream = doc.pipe(BlobStream());

      stream.on("finish", () => {
        let blob = stream.toBlob("application/pdf");
        const link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.download = `${station.name} (${station.stationID}) - ${sizeSticker} cm.pdf`;
        link.click();
      });

      doc.end();
    } catch (error) {
      console.log("ERROR downloadStickerPDF ->", error.message);
      notification.error({
        message: "Ops...",
        description: freire(texts.ERROR_SAVE_STICKER),
      });
    }
  };

  const getQrCodeSvg = () => {
    var container = document.querySelector(".qrcode_capture").cloneNode(true);

    var qrcode = container.getElementsByTagName("svg")[0];

    var logo = container.getElementsByTagName("image")[0];
    qrcode.setAttribute("xmlns", "http://www.w3.org/2000/svg");
    logo.setAttribute("xmlns:xlink", "http://www.w3.org/1999/xlink");

    return qrcode;
  };

  const showStickerModal = (sizeSticker) => {
    let qrcode = getQrCodeSvg();
    setQrcodeSticker(qrcode);

    setSizeSticker(sizeSticker);
    setVisibleSticker(true);
  };

  // const removeSwitchTag = (stickerSvg) => {
  //   var sticker = stickerSvg.cloneNode(true);
  //   var switchTag = sticker.getElementsByTagName("switch")[0];
  //   var parent = switchTag.parentNode;
  //   while (switchTag.firstChild) {
  //     parent.insertBefore(switchTag.firstChild, switchTag);
  //   }
  //   parent.removeChild(switchTag);

  //   return sticker;
  // };

  const getQrCodeLogoInfo = () => {
    if (
      [NEO, NEOCHARGE, MELI_MOOCA].includes(companyID) ||
      [NEO, NEOCHARGE, MELI_MOOCA].includes(parentCompanyID)
    ) {
      return QRCODE_NEO;
    } else if (companyID === MOBILIZE || parentCompanyID === MOBILIZE) {
      return QRCODE_MOBILIZE;
    } else if (companyID === SHELL || parentCompanyID === SHELL) {
      return QRCODE_SHELL;
    } else if (
      [BYD, BYD_TEST].includes(companyID) ||
      [BYD, BYD_TEST].includes(parentCompanyID)
    ) {
      return QRCODE_BYD;
    } else if (companyID === BRS) {
      return QRCODE_BRS;
    } else if (companyID === VOLTZ) {
      return QRCODE_VOLTZ;
    } else if (companyID === ION) {
      return QRCODE_ION;
    }

    return QRCODE_TUPI;
  };

  const { logo, sizeLogo, urlPrefix } = getQrCodeLogoInfo();

  return (
    <div className="containerQrCode">
      <StickerModal
        visible={visibleSticker}
        sizeSticker={sizeSticker}
        station={station}
        qrcode={qrcodeSticker}
        onClose={() => setVisibleSticker(false)}
        onDownload={() => downloadPDF()}
      />

      <div style={{ marginBottom: 25 }}>
        <Switch
          checkedChildren={<Icon type="check" />}
          unCheckedChildren={<Icon type="close" />}
          defaultChecked={hasLogo}
          onChange={(checked) => {
            setHasLogo(checked);
          }}
        />
        <span className="textSwitch">{freire(texts.LOGO)}</span>
      </div>
      <div className="qrcode_capture">
        <QRCode
          size={size}
          level={"H"}
          renderAs="svg"
          value={`${urlPrefix}/${station.stationID}`}
          imageSettings={{
            src: hasLogo ? logo : "",
            excavate: hasLogo,
            ...sizeLogo,
          }}
        />
      </div>

      <div style={{ marginTop: 25, alignItems: "center" }}>
        <span
          style={{
            fontSize: 16,
            fontWeight: 500,
            color: "#1890ff",
            marginRight: 15,
          }}
        >
          {freire(texts.DOWNLOAD)}:
        </span>
        <ButtonGroup>
          <Button onClick={downloadPNG} type="primary">
            PNG
          </Button>
          <Button onClick={downloadSVG} type="primary">
            SVG
          </Button>
          <Dropdown
            overlay={
              <Menu>
                <Menu.Item onClick={() => showStickerModal("16x6")} key="1">
                  Duosida/Neocharge (16x6 cm)
                </Menu.Item>
                <Menu.Item onClick={() => showStickerModal("25x12")} key="0">
                  Duosida (25x12 cm)
                </Menu.Item>
                <Menu.Item onClick={() => showStickerModal("38x16")} key="2">
                  Schneider (38x16 cm)
                </Menu.Item>
                <Menu.Item
                  onClick={() => showStickerModal("sticker_tupi_app")}
                  key="3"
                >
                  Tupi App Simples
                </Menu.Item>
                <Menu.Item
                  onClick={() => showStickerModal("sticker_tupi_app_full")}
                  key="4"
                >
                  Tupi App Completo
                </Menu.Item>
              </Menu>
            }
            trigger={["click"]}
          >
            <Button type="primary">
              {freire(texts.STICKER)}
              <Icon type="down" />
            </Button>
          </Dropdown>
        </ButtonGroup>
      </div>
    </div>
  );
}

export default QrCode;
