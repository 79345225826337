export const USER_STATUS = {
  NEWLY_CREATED: "newly_created",
  DRAFT: "draft",
  PENDING_VALIDATION: "pending_validation",
  APPROVED: "approved",
  MANUAL_APPROVED: "manual_approved",
  REJECTED: "rejected",
  SUSPENDED: "suspended",
  MANUAL_SUSPENDED: "manual_suspended",
  MANUAL_UNSUSPENDED: "manual_unsuspended",
  BLOCKED: "blocked",
};

export const ALLOWED_USER_STATUS_CHANGE = [
  USER_STATUS.MANUAL_APPROVED,
  USER_STATUS.MANUAL_SUSPENDED,
  USER_STATUS.MANUAL_UNSUSPENDED,
  USER_STATUS.BLOCKED,
];

