import {
  QRCODE_BYD,
  QRCODE_MOBILIZE,
  QRCODE_NEO,
  QRCODE_SHELL,
  QRCODE_BRS,
  QRCODE_VOLTZ,
  QRCODE_ION,
} from "../components/QrCode/configs";
import type { UnionToArray } from "../utils/types";
import { BRAND } from "./brand";
import {
  LINK_BRANCH_IO_MOBILIZE,
  LINK_BRANCH_IO_SHELL,
  LINK_BRANCH_IO_BYD,
  LINK_BRANCH_IO_BRS,
  LINK_BRANCH_IO_VOLTZ,
  LINK_BRANCH_IO_ION,
} from "./branch-io-links";

export const WHITE_LABELS = [
  {
    alias: "mobilize",
    name: "Mobilize",
    fullName: "Mobilize Charge Pass",
    emailIconUrl:
      "https://firebasestorage.googleapis.com/v0/b/tupi-910b9.appspot.com/o/emailsIcons%2Fmobilize-icon-min.png?alt=media&token=0f82b7f9-23ca-44d1-ba63-8aebc292d54c",
    emailBannerUrl:
      "https://firebasestorage.googleapis.com/v0/b/tupi-910b9.appspot.com/o/emailsIcons%2Fmobilize-min.png?alt=media&token=0dbdb681-b4ff-45ea-9e54-ecdc3576d780",
    emailFrom: BRAND.emailFrom,
    favicon: QRCODE_MOBILIZE.logo,
    linkBranchIo: LINK_BRANCH_IO_MOBILIZE,
  },
  {
    alias: "neocharge",
    name: "NeoCharge",
    fullName: BRAND.fullName,
    emailIconUrl: BRAND.emailIconUrl,
    emailBannerUrl: BRAND.emailBannerUrl,
    emailFrom: BRAND.emailFrom,
    favicon: QRCODE_NEO.logo,
  },
  {
    alias: "shell-recharge",
    name: "Shell Recharge",
    fullName: "Shell Recharge Latam",
    emailIconUrl:
      "https://firebasestorage.googleapis.com/v0/b/tupi-910b9.appspot.com/o/emailsIcons%2Fshell-recharge-icon-min.png?alt=media&token=e25ce392-5b95-4c24-b0ab-f8c4f54e6db4",
    emailBannerUrl:
      "https://firebasestorage.googleapis.com/v0/b/tupi-910b9.appspot.com/o/emailsIcons%2Fshell-recharge-banner-min.png?alt=media&token=31588154-d827-4e88-b880-1edfa694c7c9",
    qrCodeTarget: "shell",
    emailFrom: "shellrecharge@raizen.com.br",
    favicon: QRCODE_SHELL.logo,
    linkBranchIo: LINK_BRANCH_IO_SHELL,
  },
  {
    alias: "byd",
    name: "BYD Recharge",
    fullName: "BYD Recharge",
    emailIconUrl:
      "https://firebasestorage.googleapis.com/v0/b/tupi-910b9.appspot.com/o/emailsIcons%2Fbyd-recharge-icon-min.png?alt=media&token=faa08778-b9f8-422f-bf02-0d19f981d3d1",
    emailBannerUrl:
      "https://firebasestorage.googleapis.com/v0/b/tupi-910b9.appspot.com/o/emailsIcons%2Fbyd-recharge-banner-min.png?alt=media&token=73d40040-b08d-4df2-b93c-a9081e4c1a8c",
    emailFrom: BRAND.emailFrom,
    favicon: QRCODE_BYD.logo,
    linkBranchIo: LINK_BRANCH_IO_BYD,
  },
  {
    alias: "brs",
    name: "BR Super carga",
    fullName: "BR Super carga",
    emailIconUrl:
      "https://firebasestorage.googleapis.com/v0/b/tupi-910b9.appspot.com/o/emailsIcons%2Fbrs-icon.png?alt=media&token=634ed6dd-5e25-459b-a7d9-b7b80cde9d50",
    emailBannerUrl:
      "https://firebasestorage.googleapis.com/v0/b/tupi-910b9.appspot.com/o/emailsIcons%2Fbrs-banner.png?alt=media&token=77865242-8d3d-4baa-909f-d06fa7b87146",
    emailFrom: BRAND.emailFrom,
    favicon: QRCODE_BRS.logo,
    linkBranchIo: LINK_BRANCH_IO_BRS,
  },
  {
    alias: "voltz",
    name: "Voltz Eletropostos",
    fullName: "Voltz Eletropostos",
    emailIconUrl:
      "https://firebasestorage.googleapis.com/v0/b/tupi-910b9.appspot.com/o/emailsIcons%2Flogo_e-mail_voltz.png?alt=media&token=cc9e7340-96fc-456b-b72e-451541ff075e",
    emailBannerUrl:
      "https://firebasestorage.googleapis.com/v0/b/tupi-910b9.appspot.com/o/emailsIcons%2Fbanner_e-mail_voltz.png?alt=media&token=ad27c1ff-7c8a-4d26-bce9-ad1951797aec",
    emailFrom: BRAND.emailFrom,
    favicon: QRCODE_VOLTZ.logo,
    linkBranchIo: LINK_BRANCH_IO_VOLTZ,
  },
  {
    alias: "ion",
    name: "Ion Mobilidade",
    fullName: "Ion Mobilidade",
    emailIconUrl:
      "https://firebasestorage.googleapis.com/v0/b/tupi-910b9.appspot.com/o/emailsIcons%2Flogo_e-mail-ion.png?alt=media&token=f21c1880-ea35-4de5-8496-c51b8526ffee",
    emailBannerUrl:
      "https://firebasestorage.googleapis.com/v0/b/tupi-910b9.appspot.com/o/emailsIcons%2Fbanner_e-mail-ion.png?alt=media&token=045c03fc-ba3e-435c-803a-a6353d769959",
    emailFrom: BRAND.emailFrom,
    favicon: QRCODE_ION.logo,
    linkBranchIo: LINK_BRANCH_IO_ION,
  },
  // {
  //   alias: 'mercado-livre',
  //   name: 'Mercado Livre',
  //   emailIconUrl:
  //     'https://firebasestorage.googleapis.com/v0/b/tupi-910b9.appspot.com/o/emailsIcons%2Fmercadolivre-icon-min.png?alt=media&token=5eb538f8-1940-4948-be6f-5dd6420b5874',
  //   emailBannerUrl:
  //     'https://firebasestorage.googleapis.com/v0/b/tupi-910b9.appspot.com/o/emailsIcons%2Fmercadolivre-min.png?alt=media&token=9a900343-047a-411d-8b20-8fa0385792b7',
  // },
  // {
  //   alias: 'zarp',
  //   name: 'Zarp',
  //   emailIconUrl:
  //     'https://firebasestorage.googleapis.com/v0/b/tupi-910b9.appspot.com/o/emailsIcons%2Fzarp-icon-min.png?alt=media&token=5a2e9319-b4f4-404f-9959-ea96cefdd89b',
  //   emailBannerUrl:
  //     'https://firebasestorage.googleapis.com/v0/b/tupi-910b9.appspot.com/o/emailsIcons%2Fzarp-banner-min.png?alt=media&token=423ec35e-12e0-4986-9afa-334c8da188fb',
  // },
] as const;

export const WHITE_LABEL_ALIASES = WHITE_LABELS.map(
  (whiteLabel) => whiteLabel.alias
) as UnionToArray<(typeof WHITE_LABELS)[number]["alias"]>;

export type WhiteLabelAlias = (typeof WHITE_LABEL_ALIASES)[number];
